import styles from './PrivacyPolicy.module.css'; // Import the CSS file for styling

const PrivacyPolicy = () => {
    return (
        <div className={styles.termsAndConditionsPrivacy}>
           <div className={styles.header}>
                <img className="logoImg" src="/icons/logo.svg" alt="Logo"/> {/*need to upload the svg file of logo*/}
                <h1 className={styles.color}>Privacy Policy</h1>
                <h2 className={styles.color}> We Value Your Privacy</h2>
            </div>
            <br></br>
            <br></br>
            <div>
                <h3 className={styles.headings}>Our Policy:</h3>
                <p className={styles.information}>Welcome to the web site(s) (the “Site” or “Sites”) or mobile apps (the “Apps”) 
                    of QureNOTE Inc. (“Company”, “we”, “us” and/or “our”). This Site is operated by 
                    QureNOTE Inc. and has been created to provide information about our Company and 
                    our physician support services, mobile applications and related products and services 
                    (together with the Sites and/or the Apps, the “Services”) to our Service visitors and 
                    users (“you”, “your”). This Privacy Policy sets forth the Company’s policy with respect 
                    to information, including personally identifiable data (“Personal Data”) and other 
                    information that is collected from visitors to the Site and users of the Services. 
                    The Services are intended for use by physicians and other health care providers only.
                </p>
            </div>
            <div>
                <h3 className={styles.headings}>What This Policy Covers:</h3>
                <p className={styles.information}>This Privacy Policy covers our collection, use and disclosure of information about 
                    identifiable individuals and information which can be used to identify an individual 
                    (“Personal Data”). Personal Data does not include “aggregate” information which we 
                    aggregate from information (including Personal Data) you provide to us. Personal Data 
                    may be collected about our customers, prospective customers, visitors to the Website, 
                    and end users of the Services. You can choose not to provide us with certain information, 
                    but by doing so, you may not be able to take advantage of many of the features and 
                    functionality of our Services.
                </p>
                <p className={styles.information}>The Services are intended for use by health care providers (“Customers”). Our Customers 
                    are responsible for maintaining their own privacy policies governing the collection, 
                    use and disclosure of Personal Data and for obtaining the necessary authorizations and 
                    consents before any Personal Data are made available to us for use in accordance with 
                    this Privacy Policy. 
                </p>
                <p className={styles.information}>You understand and agree that the Company and its authorized business partners, 
                    affiliates, subsidiaries or agents may collect, maintain, and process information 
                    provided by you, on and through the Services. You represent and warrant that you have 
                    permission to share any information you elect to provide to QureNOTE, you consent to 
                    such information being shared with third parties as described in this Privacy Policy, 
                    and that such information is accurate, current, non-misleading, and consistent and 
                    relevant for the purpose for which you are providing information. 
                </p>
            </div>
            <div>
                <h3 className={styles.headings}>Information We Collect:</h3>
                <p className={styles.information}>When you interact with us through the Services, we may collect Personal 
                    Data about you and your patients, and other information from you, as 
                    further described below:
                </p>
                <p className={styles.information}><b className={styles.bold}>Practice Users:</b>The Company makes its Website and the Services 
                available to medical practices for a variety of uses described in our Terms 
                of Use. In order to provide the Services to our medical practice clients 
                (“Practice Users”), the Company collects certain Personal Data such as contact 
                and registration information from the Practice User employee signing up on behalf 
                of the Practice User. The Company collects and uses Practice User information the 
                same way we collect and use Personal Data.
                </p>
                <p className={styles.information}><b className={styles.bold}>Prospective Customers:</b>We collect names, email addresses and other 
                business contact information about individuals who we consider to be prospective 
                Customers and business partners (“Prospects”). The Company uses third-party 
                service providers in order to collect, store and process Personal Data about 
                Prospects. We use these third-party service providers to identify Prospects and 
                locate contact information to contact Prospects about our Services.
                </p>
                <p className={styles.information}><b className={styles.bold}>Personal Data That You Provide Through the Services:</b>We collect Personal Data 
                from you when you voluntarily provide such information, such as when you contact us 
                with inquiries, respond to one of our surveys, register for access to the Services or 
                use certain Services. Wherever the Company collects Personal Data we make an effort to 
                provide a link to this Privacy Policy. Once you create an account with us and sign in 
                to our services, you are not anonymous to us. 
                </p>
                <p className={styles.information}>As you visit or browse the Website or use the App, we collect information about the 
                    device and browser you use, your network connection, your IP address, and information 
                    about the cookies installed on your device. This information is logged to help diagnose 
                    technical problems for analytics and for quality control purposes.
                </p>
                <p className={styles.information}>
                    <b className={styles.bold}>By voluntarily providing us with Personal Data, you are consenting to our use of 
                        it in accordance with this Privacy Policy. If you provide Personal Data to the 
                        Services, you acknowledge and agree that such Personal Data may be transferred from 
                        your current location to the offices and servers of the Company and the authorized 
                        third parties referred to herein located in the United States.
                    </b>
                </p>
            </div>
            <div>
                <h3 className={styles.headings}>Other Information:</h3>
                <p className={styles.information}><b className={styles.bold}>Non-Identifiable Data:</b>When you interact with the Company through the 
                Services, we receive and store certain personally non-identifiable information. 
                Such information, which is collected passively using various technologies, cannot 
                presently be used to specifically identify you. The Company may store such 
                information itself or such information may be included in databases owned and 
                maintained by Company affiliates, agents or service providers. The Services may 
                use such information and pool it with other information to track, for example, the 
                total number of visitors to our Site or App downloads, the number of visitors to 
                each page of our Site, and the domain names of our visitors’ Internet service 
                providers. It is important to note that no Personal Data is available or used in 
                this process.</p>
                <p className={styles.information}><b className={styles.bold}>Cookies:</b>In operating the Services, we may use a technology called 
                "cookies." A cookie is a piece of information that the computer that hosts 
                our Services gives to your browser when you access the Services. Our cookies 
                help provide additional functionality to the Services and help us analyze 
                Services usage more accurately. For instance, our Site may set a cookie on 
                your browser that allows you to access the Services without needing to 
                remember and then enter a password more than once during a visit to the Site. 
                In all cases in which we use cookies, we will not collect Personal Data except 
                with your permission. On most web browsers, you will find a “help” section on 
                the toolbar. Please refer to this section for information on how to receive 
                notification when you are receiving a new cookie and how to turn cookies off. 
                We recommend that you leave cookies turned on because they allow you to take 
                advantage of some of the Service features.</p>
                <p className={styles.information}><b className={styles.bold}>Log Files:</b>Like many internet and mobile services, we may automatically 
                gather certain information about our Services traffic and store it in log files. 
                This information includes mobile device identifiers, browser type, IP address, 
                mobile or internet service provider, referring/exit pages, operating system, 
                date/time stamp, and clickstream data. We use this information, which does not 
                identify individual users, to analyze trends, to administer the Services, to 
                track users’ movements around the Services, and to gather demographic information 
                about our user base.</p>
                <p className={styles.information}>We also store in our log files information identifying users who have accessed data 
                    that we process and store about patients, and any changes they make to such data. We 
                    use this information to comply with legal obligations relating to such data and to 
                    keep a record of such changes. We may also use it to improve our Services. We may link 
                    this automatically collected data to Personal Data. Device identifiers may be tied to 
                    Personal Data to troubleshoot access issues.
                </p>
                <p className={styles.information}>We may use third-party tracking services that use log files, cookies, and possibly 
                    other techniques to track non-Personal Data about visitors to the Services in the 
                    aggregate. These services capture usage and volume statistics as well as geographical 
                    location data to compile usage reports and maps for optimization and troubleshooting 
                    purposes.
                </p>
                <p className={styles.information}><b className={styles.bold}>Aggregated Personal Data:</b>In an ongoing effort to better understand 
                and serve the users of the Services, the Company often conducts research on 
                its customer demographics, interests and behavior based on the Personal Data 
                and other information provided to us. This research may be compiled and 
                analyzed on an aggregate basis, and the Company may share this aggregate data 
                with its affiliates, agents and business partners. This aggregate information 
                does not identify you personally. The Company may also disclose aggregated user 
                statistics in order to describe our services to current and prospective business 
                partners, and to other third parties for other lawful purposes.</p>
                <p className={styles.information}><b className={styles.bold}>Our Use of Your Personal Data and Other Information:</b>The Company uses 
                the Personal Data you provide in a manner that is consistent with this Privacy Policy. 
                If you provide Personal Data for a certain reason, we may use the Personal Data in 
                connection with the reason for which it was provided. For instance, if you contact us 
                by email, we will use the Personal Data you provide to answer your question or resolve 
                your problem. Also, if you provide Personal Data in order to obtain access to the 
                Services, we will use your Personal Data to provide you with access to such services 
                and to monitor your use of such services. The Company and its subsidiaries and 
                affiliates (the “Related Companies”) may also use your Personal Data and other 
                personally non-identifiable information collected through the Services to help us 
                improve the content and functionality of the Services, to better understand our users 
                and to improve the Services. Specifically, the Company uses Personal Data to:* 
                Authenticate access to the Account and provide access to the Services;* Provide, 
                operate, maintain and improve the Services;* Send technical notices, updates, security 
                alerts and support and administrative messages;* Provide and deliver the Services and 
                features you request, process and complete transactions, and send you related information, 
                including confirmations and invoices;* Respond to comments, questions, and requests and 
                provide customer service and support;* Communicate with you about services, features, 
                surveys, newsletters, offers, promotions and events, and provide other news or information 
                about us and our select partners;* Investigate and prevent fraudulent transactions, 
                unauthorized access to the Services, and other illegal activities;* Personalize and 
                improve the Services, and provide content, features, and/or advertisements that match 
                your interests and preferences or otherwise customize your experience on the Services;* 
                Monitor and analyze trends, usage, and activities in connection with the Services and 
                for marketing or advertising purposes; and* For other purposes about which we will 
                notify you and seek your consent, or which may be reflected in our services agreement 
                with you.The Company and its affiliates may use this information to contact you in the 
                future to tell you about services we believe will be of interest to you. If we do so, 
                each marketing communication we send you will contain instructions permitting you to 
                “opt-out” of receiving future marketing communications. In addition, if at any time you 
                wish not to receive any future marketing communications or you wish to have your name 
                deleted from our mailing lists, please contact us as indicated below. If the Company 
                intends on using any Personal Data in any manner that is not consistent with this 
                Privacy Policy, you will be informed of such anticipated use prior to or at the time 
                at which the Personal Data is collected.</p>
                <p className={styles.information}><b className={styles.bold}>Online Tracking:</b>At this time, this Service does not support Do-Not-Track 
                signals.</p>
            </div>
            <div>
                <h3 className={styles.headings}>Our Disclosure of Your Personal Data and Other Information:</h3>
                <p className={styles.information}>The Company is not in the business of selling your information. We 
                    consider this information to be a vital part of our relationship with 
                    you. There are, however, certain circumstances in which we may share 
                    your Personal Data with certain third parties without further notice 
                    to you, as set forth below:
                </p>
                <p className={styles.information}><b className={styles.bold}>Processing of Medical Practice Data:</b>As a service provider to our 
                Practice Users, we collect and analyze data related to our medical practice 
                customers on their behalf. In this role, the Company is processing data upon 
                instruction from such Customer, consistent with our service agreement and 
                Business Associate Agreement with such Customer.</p>
                <p className={styles.information}>Some of the Personal Data received by the Company in connection with the 
                    Services may be provided by health care providers that are subject to laws and 
                    regulations, such as rules issued under the Health Insurance Portability and 
                    Accountability Act of 1996 (HIPAA) and Health Information Technology for 
                    Economic and Clinical Health Act of 2009 (HITECH), that govern providers’ use 
                    and disclosure of certain individually identifiable health-related Personal Data 
                    (“Protected Health Information”). When we receive Protected Health Information, 
                    we may do so as a “Business Associate” of our health care provider customers 
                    under an agreement that, among other things, prohibits us from using or disclosing 
                    the Protected Health Information in ways that are not permissible by the health care 
                    provider itself, and requires us to implement certain measures to safeguard the 
                    confidentiality, integrity, and availability of the Protected Health Information. 
                    When we act as a Business Associate, we may be subject to certain laws and regulations, 
                    including certain HIPAA rules that govern our use and disclosure of Protected Health 
                    Information and that may be more restrictive than otherwise provided in this Privacy 
                    Policy.
                </p>
                <p className={styles.information}><b className={styles.bold}>Service Providers and Business Partners:</b>We may from time to time employ third 
                parties to perform tasks on our behalf and we may need to share Personal Data with them 
                to provide certain services. Unless we tell you differently, such third parties do not 
                have any right to use the Personal Data we share with them beyond what is necessary for 
                them to provide the tasks and services on our behalf. We currently engage third party 
                companies and individuals employed by us to facilitate our Services, including the 
                provision of maintenance services, database management, Web analytics and general 
                improvement of the Services, and businesses who engage our Services (to the extent 
                provided for above).</p>
                <p className={styles.information}><b className={styles.bold}>Business Transfers:</b>As we develop our business, we might sell or buy businesses 
                or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar 
                event, Personal Data may be part of the transferred assets.</p>
                <p className={styles.information}><b className={styles.bold}>Related Companies:</b>We may also share your Personal Data with our Related 
                Companies for purposes consistent with this Privacy Policy.</p>
                <p className={styles.information}><b className={styles.bold}>Agents, Consultants and Related Third Parties:</b>The Company, like many businesses, 
                sometimes hires other companies to perform certain business-related functions. Examples 
                of such functions include mailing information, maintaining databases and processing 
                payments. When we employ another entity to perform a function of this nature, we only 
                provide them with the information that they need to perform their specific function.</p>
                <p className={styles.information}><b className={styles.bold}>Legal Requirements:</b>The Company may disclose your Personal Data if required to do 
                so by law or in the good faith belief that such action is necessary to (i) comply with 
                a legal obligation, (ii) protect and defend the rights or property of the Company, 
                (iii) act in urgent circumstances to protect the personal safety of users of the 
                Services or the public, or (iv) protect against legal liability. The above may include 
                exchanging information with other companies and organizations for fraud protection and 
                spam/malware prevention.</p>
                <p className={styles.information}><b className={styles.bold}>Your Choices:</b>You can visit the Site without providing any Personal Data. If you 
                choose not to provide any Personal Data, you may not be able to use certain Services. To 
                “opt-out” of any consents previously given to us or receiving communications from us, 
                send an e-mail to founders@QureNOTE.ai. Notwithstanding this right to request to cease 
                receiving communications from us, we retain the right to collect, maintain and process 
                information provided by and collected about you on and through the Services, and 
                disclose such information to comply with our reasonable business and legal obligations.</p>
                <p className={styles.information}><b className={styles.bold}>Retention:</b>We will keep Personal Data for as long as it remains necessary 
                for the identified purpose or as required by law, which may extend beyond the 
                termination of our relationship with you. We may retain certain data as necessary to 
                prevent fraud or future abuse, or for legitimate business purposes, such as analysis 
                of aggregated, non-personally-identifiable data, account recovery, or if required by 
                law. Unless otherwise set forth in the applicable Terms of Use or a separate agreement 
                with you governing the applicable Services, if you cease using such Service, we may 
                retain or destroy, at our discretion, all Personal Data and non-personally identifiable 
                information we collect through your use of such Service. All retained Personal Data 
                will remain subject to the terms of this Privacy Policy (or an applicable Business 
                Associate Agreement).</p>
                <p className={styles.information}><b className={styles.bold}>Exclusions:</b>This Privacy Policy does not apply to any Personal Data collected 
                by the Company other than Personal Data collected through the Services. This Privacy 
                Policy shall not apply to any unsolicited information you provide to the Company 
                through the Services or through any other means. This includes, but is not limited to, 
                information posted to any public areas of the Services, such as forums, any ideas for 
                new products or modifications to existing products, and other unsolicited submissions 
                (collectively, “Unsolicited Information”). All Unsolicited Information shall be deemed 
                to be non-confidential and the Company shall be free to reproduce, use, disclose, and 
                distribute such Unsolicited Information to others without limitation or attribution.</p>
                <p className={styles.information}><b className={styles.bold}>Children:</b>The Company does not knowingly collect Personal Data from children 
                under the age of 13. If you are under the age of 13, please do not submit any Personal 
                Data through the Services. We encourage parents and legal guardians to monitor their 
                children’s Internet usage and to help enforce our Privacy Policy by instructing their 
                children never to provide Personal Data on the Services without their permission. If 
                you have reason to believe that a child under the age of 13 has provided Personal Data 
                to the Company through the Services, please contact us, and we will endeavor to delete 
                that information from our databases.</p>
                <p className={styles.information}><b className={styles.bold}>Links to Other Web Sites:</b> This Privacy Policy applies only to the Services and 
                does not apply to the practices of companies that we do not own or control. The Services 
                may contain links to other web sites not operated or controlled by the Company (the 
                “Third Party Sites”). The policies and procedures we described here do not apply to the 
                Third Party Sites. The links from the Services do not imply that the Company endorses 
                or has reviewed the Third Party Sites. We suggest contacting those sites directly for 
                information on their privacy policies.</p>
                <p className={styles.information}><b className={styles.bold}>Security:</b>The Company takes reasonable steps to protect the Personal Data 
                provided via the Services from loss, misuse, and unauthorized access, disclosure, 
                alteration, or destruction. However, no Internet or email transmission is ever fully 
                secure or error free. In particular, email sent to or from the Services may not be 
                secure. Therefore, you should take special care in deciding what information you send 
                to us via email. Practice Users are encouraged not to email Protected Health Information 
                to the Company unless prior arrangements have been made with the Company to ensure 
                email is encrypted.</p>
                <p className={styles.information}><b className={styles.bold}>Other Terms and Conditions:</b>Your access to and use of the Services is subject 
                to the Terms of Use at www.qurenote.ai/terms</p>
                <p className={styles.information}><b className={styles.bold}>Changes to Company’s Privacy Policy:</b>The Services and our business may change 
                from time to time. As a result, at times it may be necessary for the Company to make 
                changes to this Privacy Policy. The Company reserves the right to update or modify 
                this Privacy Policy at any time and from time to time without prior notice. Please 
                review this policy periodically, and especially before you provide any Personal Data. 
                This Privacy Policy was last updated on the date indicated above. Your continued use 
                of the Services after any changes or revisions to this Privacy Policy shall indicate 
                your agreement with the terms of such revised Privacy Policy.</p>
                <p className={styles.information}><b className={styles.bold}>Access to Information; Contacting Company:</b>To keep your Personal Data accurate, 
                current, and complete, please contact us as specified below. We will take reasonable 
                steps to update or correct Personal Data in our possession that you have previously 
                submitted via the Services. As appropriate, any amended Personal Data will be transmitted 
                to those parties to which we are permitted to disclose your information. Having accurate 
                Personal Data about you enables us to give you the best possible service. In the event 
                Personal Data relates to a Practice User’s patients, and we are providing services as a 
                Business Associate, the terms of the Business Associate Agreement will dictate the 
                manner in which we update, correct, or remove Personal Health Information. In the event 
                there is a conflict between this Privacy Policy and the Business Associate Agreement, 
                the Business Associate Agreement will control.</p>
                <p className={styles.information}>Please also feel free to contact us if you have any questions about the Company’s 
                    Privacy Policy or the information practices of the Services.
                </p>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <footer className={styles.footerStyle}>
                <h5>QureNotes.ai</h5>
                <b>Version 1.0</b>
                <p style={{ fontStyle: 'italic' }}>Updated on January 15th, 2024</p>
                <p>&copy; 2024</p>
            </footer>
            <br></br>
            <br></br>
        </div>
    );
};

export default PrivacyPolicy;